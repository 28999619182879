import config from "../config.json";
import axios from "axios";
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';


String.prototype.format = function() {
  var formatted = this;
  for (var i = 0; i < arguments.length; i++) {
      var regexp = new RegExp('\\{'+i+'\\}', 'gi');
      formatted = formatted.replace(regexp, arguments[i]);
  }
  return formatted;
};

export function getEventURL(appName, event) {
  let details = config[appName] ? config[appName][event] : "";
  let partnerName = decryptValue(getAppConfig(appName),'partner');
  return details === "" ? details : getPIAddress(appName) + details.url.format(partnerName);
}

export function getAddress(appName) {
  return config[appName] ? config[appName].address : "";
}

export function getPIAddress(appName) {
  return config[appName] ? config[appName].pi_address : "";
}

export function encryptValue(appName, value){
  return CryptoAES.encrypt(value, getAppConfig(appName).encrypt_key);
}

function decryptValue(app, value){
  var _ciphertext = CryptoAES.decrypt(localStorage.getItem(value),app.encrypt_key);
  console.log(_ciphertext.toString(CryptoENC));
  return _ciphertext.toString(CryptoENC);
}

export function basicAuth(app) {
  // return "Basic " + btoa(app.username + ":" + app.password);
  return "Basic " + decryptValue(app,'user');
  // return "Basic " + localStorage.getItem('user');
}

export function getAuth(app) {
  switch (app.auth) {
    case "basic":
      return basicAuth(app);
    default:
  }
  return "";
}

export function getAppConfig(appName) {
  return config[appName];
}

export function httpGet(appName, eventName) {
  return axios.get(
    "/proxy.php",
    {
      headers: { 
        Authorization: getAuth(getAppConfig(appName)),
        // "Access-Control-Allow-Origin": getAppConfig(appName).origin
        'X-Proxy-URL': getEventURL(appName, eventName)
      },
    }
  );
}

export function httpPost(appName, eventName) {
  return axios.post(
    "/proxy.php",
    {},
    {
      headers: { Authorization: getAuth(getAppConfig(appName)),
        'X-Proxy-URL': getEventURL(appName, eventName)
      },
    }
  );
}

export function httpPut(appName, eventName, putBody) {
  return axios.put(
    "/proxy.php",
    putBody,
    {
      headers: { Authorization: getAuth(getAppConfig(appName)),
        'X-Proxy-URL': getEventURL(appName, eventName)
      },
    }
  );
}
