import React, { Component } from "react";

class Header extends Component {
  constructor(props){
    super(props);
    this.state={}
  
  this.styles = {
    fontWeight: "bold",
    color: "#fff",
  };
}

  
  render() {
    return (
      <nav className="navbar navbar-dark bg-primary justify-content-center">
        <h4 style={this.styles} className="display-5">
          OVHCloud Partner Integration Console
        </h4>
        <br />
        {/* <h5 style={this.styles} class="display-5 m-4">
          support dashboard
        </h5> */}
      </nav>
    );
  }
}

export default Header;
