import React, { Component } from "react";
import Modal1 from './Modal.js';

class UpdateTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleUpdateTicket = (details) => {
     window.alert(details);
  };

  render() {
    return (
      <main>
        <Modal1 show={this.state.show} handleClose={this.hideModal} 
        details={this.props.details} 
        handleUpdateTicket={this.handleUpdateTicket}>
        </Modal1>
        <button type="button" onClick={this.showModal}>
          Update Ticket
        </button>
      </main>
    );
  }
}

export default UpdateTicket