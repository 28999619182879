import React, { Component } from "react";
import AckModal from './AckModel.jsx';

class AckEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleAckEvents = (details) => {
     window.alert(details);
  };

  render() {
    return (
      <main>
        <AckModal show={this.state.show} handleClose={this.hideModal} 
        details={this.props.details} >
        </AckModal>
        <button type="button" onClick={this.showModal}>
          Ack Ticket
        </button>
      </main>
    );
  }
}

export default AckEvents