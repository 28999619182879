import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Main from "./components/Main";
import Header from "./components/Header";

export default function App() {
  return (
    <Router>
      <div>
        <Header/>
        <Switch>
          <Route path="/">
            <Main/>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
