import React, { Component } from 'react';
import AllTickets from './AllTickets';
import Tickets from './tickets';
import AckedTickets from './AckedTickets';

class Refresh extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showComponent: false,
            ticket_type: null
        };
        this._onButtonClick = this._onButtonClick.bind(this);
    }

    _onButtonClick() {
        console.log("++++++++" + this.props.getTicketType());
        this.setState({
            showComponent: true,
            ticket_type: this.props.getTicketType()
        });
    }

    render() {
        return (
            <div>
                <p>{this.props.ticket_type}</p>
                <button type='button' onClick={this._onButtonClick}>Apply</button>
                {
                     this.state.ticket_type === 'all' ?
                      <AllTickets /> : (this.state.ticket_type === 'unacked' ?
                       <Tickets/>: <AckedTickets/> )
                }
            </div>
        );
    }
}

export default Refresh;