import React from "react"
import './modal.css';
import { httpPut } from "./helper.js"


const Modal1 = ({ handleClose, show, children, details, handleUpdateTicket }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  const ticketIdRef = React.useRef();
  const ticketStateRef = React.useRef();
  const ticketCommentRef = React.useRef();
  const ticketResolutionCodeRef = React.useRef();
  const ticketWorknotesRef = React.useRef();
  const ticketCauseRef = React.useRef();
  const ticketResolutionNotesRef = React.useRef();

  const handleSubmit = () => {
    console.log(ticketIdRef.current.value, ticketStateRef.current.value, ticketCommentRef.current.value, ticketResolutionCodeRef.current.value);
    var putBody = JSON.stringify(
      {
        "id": ticketIdRef.current.value,
        "state": ticketStateRef.current.value,
        "comment": ticketCommentRef.current.value,
        "resolution_code": ticketResolutionCodeRef.current.value,
        "resolution_notes": ticketResolutionNotesRef.current.value,
        "work_notes": ticketWorknotesRef.current.value,
        "cause": ticketCauseRef.current.value
      }
    );

    httpPut("proxy", "updateTicket", putBody)
      .then((response) => {
        if (response.status === 200) {
          alert("ticket updated successfully");
        } else {
          alert("response: " + response.statusText);
        }
        handleClose();
      })
      .catch(error => alert(error));

  };

  return (

    <div className={showHideClassName}>
      <section >
        {children}
        {/* <div className="container"> */}
        <div>
          <h1>Update ticket</h1>
          <form>
            <label htmlFor="ticket_id">ID: </label>
            <input ref={ticketIdRef} type="text" value={details.ticket_id} readOnly></input>
            <br/>
            <label htmlFor="ticket_state">State: </label>
            <select ref={ticketStateRef} >
              <option value="new">new</option>
              <option value="open">open</option>
              <option value="canceled">canceled</option>
              <option value="awiting_info">awaiting_info</option>
              <option value="resolved">resolved</option>
            </select> <br />
            <label htmlFor="ticket_comment">Comment: </label>
            <input type="text" ref={ticketCommentRef} ></input><br />
            <label htmlFor="ticket_resolution_code">Resolution code: </label>
            <select ref={ticketResolutionCodeRef} >
              <option disabled selected value=""> -- select an option -- </option>
              <option value="solved_fixed">Solved – Fixed by Support/Guidance provided</option>
              <option value="solved_prb">Solved – Fixed by closing related PRB (Problem)</option>
              <option value="solved_workaround">Solved – Workaround provided based on open PRB (Problem)</option>
              <option value="incomplete">Incomplete – requested information not provided</option>
              <option value="solved_customer">Solved by Customer</option>
              <option value="inconclusive_scope">Inconclusive – Out of scope</option>
              <option value="inconclusive_reproduce">Inconclusive – Cannot reproduce</option>
              <option value="inconclusive_noworkaround">Inconclusive – No workaround</option>
              <option value="duplicate_issue">Duplicate Issue</option>
              <option value="voided_canceled">Voided/Canceled</option>
            </select> <br />
            <label htmlFor="ticket_resolution_notes">Resolution notes: </label>
            <input type="text" ref={ticketResolutionNotesRef} ></input><br />
            <label htmlFor="ticket_worknote">Work notes: </label>
            <input type="text" ref={ticketWorknotesRef}></input><br />
            <label htmlFor="ticket_cause">cause: </label>
            <input type="text" ref={ticketCauseRef}></input><br />
            <button type="button" style={{"background-color": "green"}} value="Submit" onClick={handleSubmit}>Submit</button>
          </form>
        </div>
        <br />
        <button type="button" style={{"background-color": "#3BB9FF"}} onClick={handleClose}>
          Close
        </button>
      </section>

    </div>
  );
};

export default Modal1;