import React  from "react"
import './modal.css';
import { httpPut } from "./helper.js"


const AckModal = ({ handleClose, show, children, details}) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";

    const ticketIdRef = React.useRef();
    const eventIdRef = React.useRef();

    const handleSubmit = () => {
        console.log(ticketIdRef.current.value, eventIdRef.current.value);

        var eventId = eventIdRef.current.value ;
        var putBody = JSON.stringify(
            {
                "events": [
                    {
                        "event_id": eventId,
                        "ticket_id": ticketIdRef.current.value
                    }
                ]
            }
        );
            console.log(putBody);

        httpPut("proxy", "ackEvents", putBody)
            .then((response) => {
                if (response.status === 200) {
                    alert("events ack'ed successfully");
                } else {
                    alert("response: " + response.statusText);
                }
                handleClose();
            })
            .catch(error => alert(error));

    };

    return (
        <div className={showHideClassName}>
            <section >
                {children}
                <div className="container">
                    <h1>Ack Events</h1>
                    <form>
                        <label htmlFor="ticket_id"> Ticket ID:
          <input ref={ticketIdRef} type="text" value={details.ticket_id} readOnly></input><br />
                        </label>
                        <br />
                        <label htmlFor="event_ids">Event Id: </label>
                        <input ref={eventIdRef} type="text" value={details.event_id} readOnly></input><br />
                        <br />
                        <button type="button" value="Submit" onClick={handleSubmit}>Submit</button>
                    </form>
                </div>
                <br />
                <button type="button" onClick={handleClose}> Close </button>
            </section>

        </div>
    );
};

export default AckModal;